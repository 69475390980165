import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import MegaMenuComponent from "./header/mega-menu";
import InfoToolbarSection from "./header/info-toolbar";
import MainToolbar from "./header/main-toolbar";
import { checkCartAndLoginCookie, RoutePageConstants } from "../../../src/utils/common";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";
import { CheckoutRoutes } from "../../../src/action-constants/checkout-constants";

/**
 * returns Desktop Header contains three components info-strip , main strip , menu strip
 *
 * @param {object} root0  props passed to the component .
 * @param {string} root0.pageName it decides whether header is needed in the layout or not
 * @param {string} root0.pageSlug router slug value
 * @returns {React.ReactElement} - React component
 */
function DHeader({ pageName, pageSlug }) {
  let header;
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.userDetails);
  const userLoggedIn = userDetails?.checkLoginInfo?.loggedin && userDetails.loginChecked;
  const callChatTime = useSelector((state) => state.commonPageData?.configureCallAndChatTime || {});

  /**
   * This method calls the checkLoginCartInfo method to fetch the login flag and the cart information
   * of the user. Further, it takes the response and dispatch the data to the store
   */
  const getLoginCartInfo = useCallback(async () => {
    if (checkCartAndLoginCookie()) {
      dispatch({
        type: APP_CONSTANTS.INITIATE_FUS,
      });
    } else {
      dispatch({
        type: APP_CONSTANTS.LOGIN_CHECKED,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    getLoginCartInfo();
  }, [getLoginCartInfo]);

  if (
    pageName === RoutePageConstants.HOME ||
    pageName === RoutePageConstants.PDP_PAGE ||
    pageName === RoutePageConstants.SEARCH ||
    pageName === RoutePageConstants.SERVER_ERROR
  ) {
    header = (
      <>
        <InfoToolbarSection showCallUs={false} />
        <MainToolbar
          showSearch
          showCart
          showAccount
          showLocationLock={!(pageName === RoutePageConstants.SEARCH || pageName === RoutePageConstants.PDP_PAGE)}
          isHomePage={pageName === RoutePageConstants.HOME}
        />
        <MegaMenuComponent />
      </>
    );
  } else if (pageName === RoutePageConstants.PLP_PAGE || pageName === RoutePageConstants.NOT_FOUND) {
    header = (
      <>
        <InfoToolbarSection showCallUs={callChatTime?.callTime?.switch !== "N"} />
        <MainToolbar showSearch showCart showAccount showLocationLock={pageName !== RoutePageConstants.NOT_FOUND} />
        <MegaMenuComponent />
      </>
    );
  } else if (pageName === RoutePageConstants.CHECKOUT_PAGE) {
    if (
      userLoggedIn &&
      (pageSlug === CheckoutRoutes.PAYMENT_FAILED || pageSlug === CheckoutRoutes.ORDER_CONFIRMATION)
    ) {
      header = (
        <>
          <InfoToolbarSection showCallUs={false} />
          <MainToolbar showSearch showCart showAccount showLocationLock={false} />
          <MegaMenuComponent />
        </>
      );
    } else {
      header = <MainToolbar showLocationLock={false} />;
    }
  } else if (pageName === RoutePageConstants.ACCOUNT_PAGE) {
    if (userLoggedIn) {
      header = (
        <>
          <InfoToolbarSection showCallUs={false} />
          <MainToolbar showSearch showCart showAccount showLocationLock={false} />
          <MegaMenuComponent />
        </>
      );
    } else {
      header = <MainToolbar showLocationLock={false} />;
    }
  } else if (pageName === RoutePageConstants.MICROSITE || pageName === RoutePageConstants.TESTIMONIAL) {
    header = (
      <>
        <InfoToolbarSection showCallUs={false} />
        <MainToolbar showSearch showCart showAccount showLocationLock={false} />
        <MegaMenuComponent />
      </>
    );
  } else if (pageName === RoutePageConstants.EXPERIENCES_PAGE) {
    header = (
      <>
        <InfoToolbarSection showCallUs={false} />
        <MainToolbar showSearch showCart showAccount showLocationLock={false} />
        <MegaMenuComponent />
      </>
    );
  }
  return <>{header}</>;
}

DHeader.propTypes = {
  pageName: PropTypes.string.isRequired,
  pageSlug: PropTypes.string,
};

DHeader.defaultProps = {
  pageSlug: "",
};

export default DHeader;
