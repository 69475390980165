import { getCleverTapInstance } from "../../components/hooks/analytics/clevertap/useCleverTapSetup";
import { cleverTapCommonWords, cleverTapEvents } from "./clevertapConstants";
import { FnpPageType, getFnpPageType, removeEmptyNullAndUndefinedFromObject } from "../utils/common";

/**
 *
 * @returns {string} according to page type
 */
export const getPageNameForCleverTap = () => {
  switch (getFnpPageType()) {
    case FnpPageType.HOME:
      return cleverTapCommonWords.HOME;
    case FnpPageType.CATEGORY:
      return cleverTapCommonWords.PLP;
    case FnpPageType.CART_PREVIEW:
      return cleverTapCommonWords.CART;
    case FnpPageType.PRODUCT:
      return cleverTapCommonWords.PRODUCT;
    default:
      return "";
  }
};

/**
 * This is a utility method to call clevertap events
 *
 * @param {string} eventName - contains event name
 * @param {object} eventData - contains object to be shared in event
 * @param {string} eventType - contains event type
 */
function callClevertapEvent(eventName, eventData, eventType = cleverTapEvents.common) {
  const cleverTapInstance = getCleverTapInstance();
  const filteredEventData = removeEmptyNullAndUndefinedFromObject(eventData);
  if (cleverTapInstance) {
    try {
      switch (eventType) {
        case cleverTapEvents.common:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
        case cleverTapEvents.userLogin:
          cleverTapInstance.onUserLogin.push(filteredEventData);
          break;
        default:
          cleverTapInstance.event.push(eventName, filteredEventData);
          break;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("CleverTap event push failed:", error);
    }
  } else {
    // eslint-disable-next-line no-console
    console.warn("CleverTap instance is not initialized.");
  }
}

export default callClevertapEvent;
