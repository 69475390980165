import { HYDRATE } from "next-redux-wrapper";
import CHECKOUT_CONSTANTS from "../../src/action-constants/checkout-constants";
import PAYMENT_CONSTANTS from "../../src/action-constants/payment-constants";

const initialState = {
  cartExpiry: {},
  coupons: {},
  cartItems: {},
  senderInfo: {},
  priceSurgeDetails: {},
  viewCart: {},
  occasionsAndTemplateMsgs: {},
  cartSummaryAmountDetail: {},
  encodedCustomerDetails: {},
  frequentlyBoughtAddons: [],
  getCardMessageDetails: {},
  salutations: [],
  addressDetails: {},
  savedAddressForPinCode: {},
  checkoutPageData: {},
  cartUpdateData: {},
  cakeMessages: [],
  selectedContactMechIdList: {},
  addressFormDetails: null,
  isEditAddress: false,
  couponError: null,
  isCartItemExpired: null,
  loadingCoupon: false,
  loadingAddons: false,
  loadingInitCheckout: true,
  loadingAddNewAddress: false,
  loadingFrequentlyBought: false,
  loadingMessageCardMobile: true,
  showCouponInput: false,
  deleteLoader: false,
  specialCountries: "",
  vendorURLs: {},
  shippingDatesAndMethods: {},
  internationalShippingTypes: {},
  internationalDeliveryDates: {},
  addressLoader: false,
  couponApplied: false,
  validateAddressForm: false,
  loginRedirection: true,
  shippingDatesAndMethodsLoader: false,
  termsAndConditions: "",
  donation: {},
  donationInfo: "",
  donationAmount: {},
  donationEnable: {},
  addonsFetched: false,
  addonNames: [],
  deliveryDatesLoader: false,
};

/**
 * This method is the reducer for actions for checkout
 *
 * @param {object} state - contains initial redux State
 * @param {object} action - contains actionTypes and payload information for specific action
 * @returns {object} state - the next State which needs to be dispatched to redux
 */
function checkoutReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case HYDRATE:
      return {
        ...state,
        ...action.payload.checkout,
      };
    case CHECKOUT_CONSTANTS.INITIATE_CHECKOUT:
      return { ...state, loadingInitCheckout: true };

    case PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS:
      return { ...state, loadingInitCheckout: true };

    case CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_FAILED:
      return { ...state, loadingInitCheckout: false };

    case PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS_FAILED:
      return { ...state, loadingInitCheckout: false };

    case CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_SUCCESS:
      return {
        ...state,
        cartExpiry: payload.cartExpiry,
        coupons: payload.coupons,
        cartItems: payload.cartItems,
        senderInfo: payload.senderInfo,
        priceSurgeDetails: payload.priceSurgeDetails,
        occasionsAndTemplateMsgs: payload.occasionsAndTemplateMsgs,
        cartSummaryAmountDetail: payload.cartSummaryAmountDetail,
        encodedCustomerDetails: payload.encodedCustomerDetails,
        loadingInitCheckout: false,
        donationAmount: payload.donationAmount,
        donationEnable: payload.donationEnable,
      };

    case PAYMENT_CONSTANTS.FETCH_PAYMENT_OPTIONS_METHODS_SUCCESS:
      return {
        ...state,
        cartItems: payload.cartItems,
        viewCart: payload.viewCart,
        cartSummaryAmountDetail: payload.cartSummaryAmountDetail,
        encodedCustomerDetails: payload.encodedCustomerDetails,
        loadingInitCheckout: false,
        donationAmount: payload.donationAmount,
        donationEnable: payload.donationEnable,
      };

    case CHECKOUT_CONSTANTS.INITIATE_CHECKOUT_UPDATED_CART_SUCCESS:
      return {
        ...state,
        cartExpiry: payload.cartExpiry,
        cartItems: payload.cartItems,
        viewCart: payload.viewCart,
        cartSummaryAmountDetail: payload.cartSummaryAmountDetail,
        loadingInitCheckout: false,
      };

    case CHECKOUT_CONSTANTS.UPDATE_SENDER_INFO:
      return { ...state, senderInfo: payload };

    case CHECKOUT_CONSTANTS.SET_SHOW_COUPON_INPUT:
      return { ...state, showCouponInput: payload };

    case CHECKOUT_CONSTANTS.SET_ADDRESS_DETAILS:
      return { ...state, addressFormDetails: payload };

    case CHECKOUT_CONSTANTS.SET_IS_EDIT_ADDRESS:
      return { ...state, isEditAddress: payload };

    case CHECKOUT_CONSTANTS.SET_GIFT_ITEM_DEFAULT_ADDRESS:
      return { ...state, selectedContactMechIdList: payload };

    case CHECKOUT_CONSTANTS.GET_CARD_MESSAGE_DETAILS_SUCCESS:
      return { ...state, getCardMessageDetails: payload.getCardMessageDetails };

    case CHECKOUT_CONSTANTS.FREQUENTLY_BOUGHT_ADDONS_SUCCESS:
      return { ...state, frequentlyBoughtAddons: payload.frequentlyBoughtAddons, addonsFetched: true };

    case CHECKOUT_CONSTANTS.FETCH_MESSAGE_CARD_MOBILE_SUCCESS:
      return {
        ...state,
        occasionsAndTemplateMsgs: payload.occasionsAndTemplateMsgs,
        getCardMessageDetails: payload.getCardMessageDetails,
        loadingMessageCardMobile: false,
      };

    case CHECKOUT_CONSTANTS.FETCH_MESSAGE_CARD_MOBILE_FAILED:
      return { ...state, loadingMessageCardMobile: false };

    case CHECKOUT_CONSTANTS.APPLY_COUPON:
      return {
        ...state,
        loadingCoupon: action.payload.isModel ? { modelLoader: true, priceDetails_Loader: true } : true,
        couponError: null,
      };

    case CHECKOUT_CONSTANTS.APPLY_COUPON_SUCCESS:
      return {
        ...state,
        loadingCoupon: false,
        cartSummaryAmountDetail: {
          ...state.cartSummaryAmountDetail,
          priceDetails: {
            ...state.cartSummaryAmountDetail.priceDetails,
            orderSubTotal: payload.data.orderSubTotal,
            totalDiscountAmount: payload.data.totalDiscountAmount,
            orderShippingTotal: payload.data.orderShippingTotal,
            additionalPromoAdjustment: payload.data.additionalPromoAdjustment,
            orderGrandTotal: payload.data.orderGrandTotal,
            couponCode: payload.data.couponCode,
            promoMsg: payload.data.promoMsg,
          },
        },
      };
    case CHECKOUT_CONSTANTS.COUPON_APPLIED:
      return { ...state, couponApplied: action.payload };

    case CHECKOUT_CONSTANTS.APPLY_COUPON_FAILED:
      return { ...state, loadingCoupon: false, couponError: payload.error };

    case CHECKOUT_CONSTANTS.SET_ITEM_ATTRIBUTES_FOR_ORDER_SUCCESS:
      return { ...state, cakeMessages: action.payload };

    case CHECKOUT_CONSTANTS.REMOVE_COUPON:
      return { ...state, loadingCoupon: true };

    case CHECKOUT_CONSTANTS.CLEAR_COUPON_ERROR:
      return { ...state, couponError: null };

    case CHECKOUT_CONSTANTS.REMOVE_COUPON_SUCCESS:
      return {
        ...state,
        loadingCoupon: false,
        couponError: null,
        showCouponInput: false,
        cartSummaryAmountDetail: {
          ...state.cartSummaryAmountDetail,
          priceDetails: {
            ...state.cartSummaryAmountDetail.priceDetails,
            orderSubTotal: payload.data.orderSubTotal,
            totalDiscountAmount: payload.data.totalDiscountAmount,
            orderShippingTotal: payload.data.orderShippingTotal,
            additionalPromoAdjustment: payload.data.additionalPromoAdjustment,
            orderGrandTotal: payload.data.orderGrandTotal,
            couponCode: payload.data.couponCode,
            promoMsg: payload.data.promoMsg,
          },
        },
      };

    case CHECKOUT_CONSTANTS.REMOVE_COUPON_FAILED:
      return { ...state, loadingCoupon: false, couponError: null, showCouponInput: false };

    case CHECKOUT_CONSTANTS.FETCH_COUPONS_SUCCESS:
      return { ...state, coupons: payload.data };

    case CHECKOUT_CONSTANTS.FETCH_COMPLETE_CART_SUCCESS:
      return { ...state, cartItems: payload.data };

    case CHECKOUT_CONSTANTS.FETCH_VIEW_CART_SUCCESS:
      return { ...state, viewCart: payload.data };

    case CHECKOUT_CONSTANTS.FETCH_CART_EXPIRY_SUCCESS:
      return { ...state, cartExpiry: payload.data };
    case CHECKOUT_CONSTANTS.VALIDATE_ADDRESS_FORM:
      return { ...state, validateAddressForm: action.payload };

    case CHECKOUT_CONSTANTS.FETCH_CART_SUMMARY_AMOUNT_SUCCESS:
      return { ...state, cartSummaryAmountDetail: payload.data, loadingCoupon: false };

    case CHECKOUT_CONSTANTS.ADD_ADDON_TO_CART:
      return { ...state, loadingFrequentlyBought: true };
    case CHECKOUT_CONSTANTS.SET_ADDONS_NAMES:
      return { ...state, addonNames: payload.data };
    case CHECKOUT_CONSTANTS.ADD_ADDON_TO_CART_SUCCESS:
      return { ...state, loadingFrequentlyBought: false };

    case CHECKOUT_CONSTANTS.ADD_ADDON_TO_CART_FAILED:
      return { ...state, loadingFrequentlyBought: false };

    case CHECKOUT_CONSTANTS.UPDATE_ADDON_QTY:
      return { ...state, loadingAddons: true };

    case CHECKOUT_CONSTANTS.UPDATE_ADDON_QTY_SUCCESS:
      return { ...state, loadingAddons: false };

    case CHECKOUT_CONSTANTS.UPDATE_ADDON_QTY_FAILED:
      return { ...state, loadingAddons: false };

    case CHECKOUT_CONSTANTS.DELETE_ITEM:
      return {
        ...state,
        loadingFrequentlyBought: action.payload.isFrequentAddOns,
        deleteLoader: action.payload.deleteLoader,
      };

    case CHECKOUT_CONSTANTS.DELETE_ITEM_FAILED:
      return { ...state, loadingFrequentlyBought: false, deleteLoader: false };

    case CHECKOUT_CONSTANTS.DELETE_ITEM_SUCCESS:
      return { ...state, loadingFrequentlyBought: false, deleteLoader: false };

    case CHECKOUT_CONSTANTS.GET_SALUTATIONS_SUCCESS:
      return { ...state, salutations: action.payload };

    case CHECKOUT_CONSTANTS.INITIATE_ADD_NEW_ADDRESS:
      return { ...state, loadingAddNewAddress: true };

    case CHECKOUT_CONSTANTS.INITIATE_ADD_NEW_ADDRESS_SUCCESS:
      return {
        ...state,
        loadingAddNewAddress: false,
        cartItems: action.payload.cartItems,
        salutations: action.payload.salutations,
        savedAddressForPinCode: action.payload.savedAddressForPinCode,
      };

    case CHECKOUT_CONSTANTS.INITIATE_ADD_NEW_ADDRESS_FAILED:
      return { ...state, loadingAddNewAddress: false };

    case CHECKOUT_CONSTANTS.CART_ITEM_EXPIRED:
      return { ...state, isCartItemExpired: action.payload };

    case CHECKOUT_CONSTANTS.SET_CHECKOUT_PAGE_DATA:
      return { ...state, checkoutPageData: { ...state.checkoutPageData, ...action.payload } };

    case CHECKOUT_CONSTANTS.APPLY_MANUAL_ADJUSTMENT:
      return { ...state, loadingCoupon: true };

    case CHECKOUT_CONSTANTS.APPLY_MANUAL_ADJUSTMENT_FAILED:
      return { ...state, loadingCoupon: false };

    case CHECKOUT_CONSTANTS.REMOVE_MANUAL_ADJUSTMENT:
      return { ...state, loadingCoupon: true };

    case CHECKOUT_CONSTANTS.REMOVE_MANUAL_ADJUSTMENT_FAILED:
      return { ...state, loadingCoupon: false };
    case CHECKOUT_CONSTANTS.UPDATE_SENDER_CONTACT_NUMBER:
      return {
        ...state,
        senderInfo: {
          ...state.senderInfo,
          primaryContactNumber: action.payload.primaryContactNumber,
        },
      };
    case CHECKOUT_CONSTANTS.UPDATE_SENDER_MOBILE_COUNTRY_CODE:
      return {
        ...state,
        senderInfo: {
          ...state.senderInfo,
          primaryCountryCode: action.payload,
        },
      };
    case CHECKOUT_CONSTANTS.UPDATE_SENDER_MOBILE_COUNTRY_CODE_LABEL:
      return {
        ...state,
        senderInfo: {
          ...state.senderInfo,
          primaryCountryCodeLabel: action.payload,
        },
      };
    case CHECKOUT_CONSTANTS.SET_UPDATE_ADDRESS_CART_INDEX: {
      return {
        ...state,
        updateAddressCartIndex: payload.updateAddressCartIndex,
      };
    }
    case CHECKOUT_CONSTANTS.UPDATE_CART_FROM_CHECKOUT_PAGE_SUCCESS: {
      return { ...state, cartUpdateData: payload.data, cartSummaryAmountDetail: payload?.cartSummaryAmountDetail };
    }
    case CHECKOUT_CONSTANTS.CLEAR_CART_UPDATE_DATA: {
      return { ...state, cartUpdateData: initialState.cartUpdateData };
    }
    case CHECKOUT_CONSTANTS.GET_SPL_COUNTRY_GEO_IDS_SUCCESS:
      return { ...state, specialCountries: action.payload };
    case CHECKOUT_CONSTANTS.GET_VENDOR_URLS_SUCCESS:
      return { ...state, vendorURLs: action.payload };
    case CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES_SUCCESS:
      return { ...state, shippingDatesAndMethods: action.payload, shippingDatesAndMethodsLoader: false };
    case CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES_FAILED:
      return { ...state, shippingDatesAndMethodsLoader: false };
    case CHECKOUT_CONSTANTS.SHIPPING_AND_DELIVERY_DATES_FETCH_START:
      return {
        ...state,
        shippingDatesAndMethods: {},
        internationalShippingTypes: {},
        internationalDeliveryDates: {},
        shippingDatesAndMethodsLoader: true,
      };
    case CHECKOUT_CONSTANTS.SET_SHIPPING_DETAILS:
      return {
        ...state,
        shippingDatesAndMethods: {
          ...state.shippingDatesAndMethods,
          ...action.payload,
        },
      };
    case CHECKOUT_CONSTANTS.SHIPPING_DETAILS_FETCH_START:
    case CHECKOUT_CONSTANTS.SHIPPING_DETAILS_FETCH_ERROR:
      return {
        ...state,
        shippingDatesAndMethods: {
          ...state.shippingDatesAndMethods,
          shippingDetails: {},
          isShippingDetailsExists: false,
        },
      };
    case CHECKOUT_CONSTANTS.GET_INTL_SHIPPING_SUCCESS:
      return { ...state, internationalShippingTypes: action.payload };
    case CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES_FAILED:
      return { ...state, deliveryDatesLoader: false };
    case CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES_FETCH_START:
      return {
        ...state,
        deliveryDatesLoader: true,
      };
    case CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES_SUCCESS:
      return { ...state, internationalDeliveryDates: action.payload, deliveryDatesLoader: false };
    case CHECKOUT_CONSTANTS.DELETE_ITEM_ADDRESS:
      return { ...state, addressLoader: action.payload?.addressLoader };
    case CHECKOUT_CONSTANTS.DELETE_ITEM_ADDRESS_FAILED:
      return { ...state, addressLoader: false };
    case CHECKOUT_CONSTANTS.DELETE_ITEM_ADDRESS_SUCCESS:
      return { ...state, addressLoader: false };
    case CHECKOUT_CONSTANTS.SET_LOGIN_REDIRECTION:
      return { ...state, loginRedirection: action.payload };
    case CHECKOUT_CONSTANTS.SET_SHIPPING_INSTRUCTIONS_SUCCESS:
      return { ...state, cartItems: payload };
    case CHECKOUT_CONSTANTS.SET_SURGE_PRICE_DETAILS:
      return { ...state, priceSurgeDetails: payload };
    case CHECKOUT_CONSTANTS.GET_TNC_DETAILS:
      return { ...state, termsAndConditions: payload };
    case CHECKOUT_CONSTANTS.DONATION_CHANGE_SUCCESS:
      return { ...state, donation: payload };
    case CHECKOUT_CONSTANTS.DONATION_INFO_SUCCESS:
      return { ...state, donationInfo: payload };
    default:
      return state;
  }
}

export default checkoutReducer;
