import { Dialog, makeStyles } from "@material-ui/core";
import dynamic from "next/dynamic";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";
import getConfig from "next/config";
import APP_CONSTANTS from "../../../../../src/action-constants/app-actions";
import { MY_CART } from "../../../../common/constants";
import CartPreview from "../../../../widget/cart";
import CartIcon from "./cart-icon";
import styles from "./cart-preview.module.scss";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../../../src/analytics/clevertapConstants";
import callClevertapEvent from "../../../../../src/analytics/clevertapUtility";

const { publicRuntimeConfig } = getConfig();

const AddOns = dynamic(() => import("../../../../widget/addOns/addon"), { ssr: false });

const useStyles = makeStyles({
  desktopAddOnPaper: {
    backgroundColor: "#000000cc",
    position: "fixed",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "99999",
  },
});
/**
 * Component of Cart strip
 *
 * @returns {React.ReactElement} returns the Cart dropdown  component.
 */
function Cart() {
  const router = useRouter();
  const [currentAddOnData, setCurrentAddOnData] = useState({});
  const classes = useStyles();

  const { showBackDrop, priceDetails } = useSelector((state) => state.cartDetails);
  const { showAddOnPopup } = useSelector((state) => state.addOnDetails);
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();
  let mouseOverEnter;

  const getCartDataFromLocalStorage =
    typeof window !== "undefined" && JSON.parse(window?.localStorage?.getItem("Cart"));
  const cartData = getCartDataFromLocalStorage?.data || {};
  const pricingSummary = cartData.pricingSummary || {};
  const additionalPromoAdjustment = cartData.additionalPromoAdjustment?.[0] || {};
  const items = cartData.items || [];
  const host = publicRuntimeConfig.D_HOST;

  const cleverTapCartCommonData = {
    complete_url: `${host}${router.asPath}`,
    clean_url: `${host}${router.asPath}`.split("?")[0],
    page_name: cleverTapCommonWords.CART,
    page_type: cleverTapCommonWords.CART,
    cart_value: cartData.total ? Number(cartData.total) : 0,
    total_discount: pricingSummary.orderSavedAmount ?? 0,
    total_delivery_fee: Number(cartData.totalShipping) || 0,
    convenience_fee: additionalPromoAdjustment.additionalAmount ?? 0,
    total_product_count: items.length ?? 0,
    login_status: userDetails?.checkLoginInfo?.loggedin ?? false,
    is_cart_empty: items.length === 0,
    donation_amount: cartData.donationAmount ?? 0,
    coupon_discount: pricingSummary.couponDiscount ?? 0,
  };

  useEffect(() => {
    if (!showAddOnPopup) dispatch({ type: APP_CONSTANTS.CLEAR_ADDON_PRODUCT_LIST });
  }, [dispatch, showAddOnPopup]);

  /**
   * This function dispatch an action for the backdrop of desktop screen when mouseover on cart icon
   */
  const handleMouseEnter = () => {
    const additionalCartData = { ...cleverTapCartCommonData, fnp_cash_discount: undefined };
    callClevertapEvent(cleverTapCommonEvents.cartViewed, additionalCartData);

    if ((priceDetails?.noOfBaseItems || userDetails?.cartInfo?.cartTotalQuantity > 0) && !showBackDrop)
      dispatch({ type: APP_CONSTANTS.GET_CART_SUMMARY_INFO_REQUEST });
    mouseOverEnter = setTimeout(() => {
      dispatch({ type: APP_CONSTANTS.SHOW_BACKDROP_REQUEST, payload: true });
    }, 300);
  };

  /**
   * THis function handles the mouseleave form the cart icon in header
   */
  const handleMouseLeave = () => {
    if (!showAddOnPopup) {
      clearTimeout(mouseOverEnter);
      dispatch({ type: APP_CONSTANTS.SHOW_BACKDROP_REQUEST, payload: false });
    }
  };

  /**
   * This function closes the cart preview container
   */
  const handleCloseCartContainer = () => {
    dispatch({ type: APP_CONSTANTS.SHOW_BACKDROP_REQUEST, payload: false });
  };

  /**
   * This function opens the AddOn popup
   *
   * @param {object} giftItem object of data of current selected product in cart preview
   */
  const proceedToAddonsPage = (giftItem) => {
    dispatch({ type: APP_CONSTANTS.SHOW_ADDON_REQUEST, payload: true });
    const addonProductIds = [];
    giftItem?.addons?.forEach((item) => {
      addonProductIds.push(`${item.productId}_${item.itemIndex}_${item.quantity}_${item.price}`);
    });
    setCurrentAddOnData({ ...giftItem, addonProductIds, from: "Make it extra special" });
  };
  return (
    <>
      <div className={styles["cart-icon-container"]} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={styles["cart-icon-section"]}>
          <CartIcon cartSize={userDetails?.cartInfo?.cartTotalQuantity} />
        </div>
        {showBackDrop && (
          <div className={styles["backdrop-cart-container"]}>
            <div className={styles["desktop-cart-heading"]}>
              {MY_CART}
              {priceDetails?.noOfBaseItems ? `(${priceDetails?.noOfBaseItems})` : ""}
              <button type="button" className={styles["desktop-cart-close-btn"]} onClick={handleCloseCartContainer}>
                <i className="material-icons">close</i>
              </button>
            </div>
            <CartPreview handleProceedToAddon={proceedToAddonsPage} />
          </div>
        )}
      </div>

      {showAddOnPopup && (
        <Dialog className={classes.desktopAddOnPaper} open={showAddOnPopup}>
          <AddOns
            addProductId={currentAddOnData.productId}
            isBuyNow={currentAddOnData.isBuyNow || "N"}
            pinCode={currentAddOnData.pincode}
            productId={currentAddOnData.productId}
            productType={currentAddOnData.productType}
            shippingMethod={currentAddOnData.shippingDetails.shippingMethodId}
            timeSlotId={currentAddOnData.shippingDetails.deliveryTimeSlotId}
            itemDesiredDeliveryDate={currentAddOnData.shippingDetails.deliveryDate.fullDeliveryDate}
            addonProductId={currentAddOnData.addonProductIds}
            orderItemSeqId={currentAddOnData.orderItemSeqId}
            prdShippingPrice={currentAddOnData.shippingDetails.shippingPrice}
            productPrice={currentAddOnData.price}
            from={currentAddOnData.from}
          />
        </Dialog>
      )}
    </>
  );
}

export default Cart;
