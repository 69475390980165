import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import style from "./same-mid-day-delivery.module.scss";

/**
 * Component for displaying same-day or mid-day delivery options based on event data.
 *
 * @function
 * @returns {React.ReactNode} JSX element representing the delivery button.
 */
export default function SameMidDayDelivery() {
  const [sameDay, setSameDay] = useState(null);
  const [midDay, setMidDay] = useState(null);

  const router = useRouter();

  useEffect(() => {
    /**
     *
     * Handles the "CT_web_native_display" event and sets the appropriate state
     * based on the delivery topic.
     *
     * @param {object} event .
     */
    const handleEvent = (event) => {
      if (event.detail.kv.topic === "same-day") {
        setSameDay(event.detail.kv);
      }
      if (event.detail.kv.topic === "mid-day") {
        setMidDay(event.detail.kv);
      }
    };

    document.addEventListener("CT_web_native_display", handleEvent);

    return () => {
      document.removeEventListener("CT_web_native_display", handleEvent);
    };
  }, []);

  /**
   * Navigates to the provided URL.
   *
   * @param {string} url - The URL to navigate to.
   */
  const handleOnClick = (url) => {
    router.push(url);
  };

  /**
   * Renders a button with the given data.
   *
   * @param {object} data - The data object containing button details.
   * @param {string} data.name - The name to display on the button.
   * @param {string} data.url - The URL to navigate to when clicking the button.
   * @param {string} data.backgroundColor - The background color for the button.
   * @param {string} data.fontColor - The font color for the button.
   * @returns {React.Element} JSX element representing the button.
   */
  const Button = (data) => {
    return (
      data?.name && (
        <div className={style.wrapper}>
          <button
            type="button"
            onClick={() => handleOnClick(data.url)}
            style={{ backgroundColor: data.backgroundColor ? data.backgroundColor : "#d0af22" }}
            className={style.btn}
          >
            <span style={{ color: data.fontColor ? data.fontColor : "#000" }}>{data.name}</span>
          </button>
        </div>
      )
    );
  };

  if (sameDay) {
    return Button(sameDay);
  }

  if (midDay) {
    return Button(midDay);
  }

  return null;
}
