import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCookie } from "nookies";
import Dropdown from "../../hoc/dropdown/index";
import style from "./currency-dropdown.module.scss";
import dropDownstyle from "../../hoc/dropdown/drop-down.module.scss";
import { EMPTY_VIEW_LABEL } from "../../../../../common/constants";
import ActionConstants from "../../../../../../src/action-constants/app-actions";
import callClevertapEvent, { getPageNameForCleverTap } from "../../../../../../src/analytics/clevertapUtility";
import { cleverTapCommonEvents } from "../../../../../../src/analytics/clevertapConstants";

/**
 * Component of currency DropDown
 *
 * @returns {React.ReactElement} retuns the currency dropdown component.
 */
function CurrencyDropdown() {
  const dispatch = useDispatch();
  const currencyList = useSelector((state) => state.currenciesList.currencies);
  const selectedCurrency = useSelector((store) => store.appData.selectedCurrency);
  const appConfig = useSelector((state) => state.appConfigs);
  const domain = appConfig.configData.default.cookieDomain;

  const pageHeaderMenuData = {
    page_name: getPageNameForCleverTap(),
    page_type: getPageNameForCleverTap(),
    menu_name: "Currency",
  };

  /**
   * This method handles the currency dropdown select
   *
   * @param {string} value props
   * @returns {void} retuns nothing.
   */
  const handleCurrencySelect = (value) => {
    callClevertapEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData);
    dispatch({
      type: ActionConstants.UPDATE_SELECTED_CURRENCY,
      payload: value.currency.id,
    });
    setCookie(null, "localCurrency", value.currency.id, { path: "/", domain });
  };

  /**
   * Returns the entire currency elements
   *
   * @param {Array} items currencies
   * @returns {React.ReactElement} retuns the currency dropdown elements, if items are not empty else retuns empty string
   */
  const renderMenu = (items) => {
    const EmptyView = <div className="account-dropdown__header">{EMPTY_VIEW_LABEL}</div>;

    const ListView = <ul className="menu">{items}</ul>;

    const showEmptyView = items.length === 0;

    return (
      <div
        className={`${dropDownstyle["menu-container"]} ${style["menu-width"]} ${style["currency-dropdown"]} ${dropDownstyle["more-currency-arrow"]}`}
        isScroll
        flow
        width="250px"
      >
        {showEmptyView ? EmptyView : ListView}
      </div>
    );
  };
  /**
   * currency detail
   *
   * @param {Array} item currencies
   * @returns {React.ReactElement} retuns the detail of curency like name-symbol
   */
  const renderItem = (item) => (
    <li className={dropDownstyle["menu-list"]} key={item.currency.id}>
      {item.currency.id}
      <span> - </span>
      {item.currency.name}
    </li>
  );

  return (
    <Dropdown
      items={currencyList}
      renderItem={renderItem}
      renderMenu={renderMenu}
      getItemValue={handleCurrencySelect}
      arrow="currency-arrow"
    >
      <div className={`${dropDownstyle["info-toolbar-dropdown-placeholder"]}`}>
        <span className={style["light-text"]}>Currency</span>
        <b className={style["bold-text"]}>{selectedCurrency}</b>
        <span>
          <i className="material-icons">expand_more</i>
        </span>
      </div>
    </Dropdown>
  );
}

export default CurrencyDropdown;
