import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dynamic from "next/dynamic";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import {
  dateToDMY,
  FnpPageType,
  getFnpPageType,
  getProductDeliveryType,
  isDateTodayOrTomorrow,
  sanitizeDateStringToDate,
} from "../../src/utils/common";
import ModalFullscreenAnimated from "../mobile/pdp/modal-fullscreen-animated";
import autoSuggestStyles from "../mobile/pdp/style/autosuggest.module.scss";
import CHECKOUT_CONSTANTS from "../../src/action-constants/checkout-constants";
import { textConst } from "../../const/text-english";
import { removeItemBasedOnKey } from "../../src/utils/search-utils";
import { checkIsInternationalProduct } from "../../src/utils/checkout";
import { cleverTapCommonEvents } from "../../src/analytics/clevertapConstants";
import { CAKE_TYPE } from "../../src/utils/pdp-constants";
import callClevertapEvent from "../../src/analytics/clevertapUtility";
import { getShippingDetails } from "../../src/actions/checkout-actions";

const DeliveryOnCalender = dynamic(() => import("../mobile/pdp/product-delivery/deliver-on/calender"), { ssr: false });
const SelectDeliveryTimeSlot = dynamic(
  () => import("../mobile/pdp/product-delivery/deliver-on/select-delivery-time-slot"),
  { ssr: false },
);

/**
 * This method is used to render International Shipping selection and Delivery Calender
 *
 * @param {object} root0 it includes object from parent component
 * @param {Function} root0.onSelect to handle selection.
 * @param {object} root0.giftItem Gift item
 * @param {Function} root0.getPrice to handle price.
 * @param {Function} root0.onClose to close component.
 * @param {object} root0.cleverTapCartCommonData .
 * @returns {React.ReactElement} jsx for the International Shipping selection and Delivery Calender.
 */
function CheckoutInternationalDeliver({ onSelect, giftItem, getPrice, onClose, cleverTapCartCommonData }) {
  const initialDate = sanitizeDateStringToDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate) || null;
  const [open, setOpen] = useState(true);
  const [date, setDate] = useState(initialDate);
  const [calenderScreen, setCalenderScreen] = useState(false);
  const [deliverySlot, setDeliverySlotScreen] = useState(false);
  const [deliveryMethod, setDeliveryMethodScreen] = useState(true);
  const [isSameShippingSelected, setIsSameShippingSelected] = useState(false);
  const [isSameDateSelected, setIsSameDateSelected] = useState(false);

  const shippingEvents = useSelector((state) => state.checkout?.shippingDatesAndMethods?.shippingEvents);
  const internationalShippingTypes = useSelector((state) => state.checkout?.internationalShippingTypes);
  const internationalDeliveryDates = useSelector((state) => state.checkout?.internationalDeliveryDates);
  const shippingDatesAndMethodsLoader = useSelector((state) => state.checkout?.shippingDatesAndMethodsLoader);
  const { catalogId } = useSelector((state) => state.appConfigs);
  const { selectedType } = useSelector((state) => state.pdpContext);
  const deliveryDatesLoader = useSelector((state) => state.checkout?.deliveryDatesLoader);
  const { deliveryDates } = internationalDeliveryDates;
  const { shippingDetails, isShippingDetailsExists } = internationalShippingTypes;
  const priceSurgeDetails = useSelector((state) => state.checkout?.priceSurgeDetails);
  const productPriceByDate = useSelector((state) => state.productDetail?.productPriceByDate);
  const currency = useSelector((state) => state.appData?.selectedCurrency);
  const currencyCode = currency === "INR" ? "₹" : currency;
  const [selectedMethodByUser, setSelectedMethodByUser] = useState({});
  const [userSelectedDate, setUserSelectedDate] = useState(null);
  const [domesticShippingDetails, setDomesticShippingDetails] = useState({});

  const dispatch = useDispatch();

  const isCartPreviewPage = getFnpPageType() === FnpPageType.CART_PREVIEW;
  const { nextAvailableDateDiff } = shippingEvents || {};
  const productDeliveryType = getProductDeliveryType(giftItem?.primaryProductCategoryId);

  const cleverTapData = {
    product_name: giftItem.productName,
    pid: giftItem.productId,
    product_category: giftItem.primaryProductCategoryId,
    product_price: giftItem.price,
    is_variant_available: giftItem.isVariant,
    is_egg: selectedType.toUpperCase() === CAKE_TYPE.WITH_EGG,
    delivery_type: productDeliveryType,
    pincode: giftItem.pincode,
    area: giftItem.deliveryLocation,
    system_pincode: giftItem.pincode,
    country: catalogId,
    city: giftItem.deliveryLocation,
    is_international: giftItem.isIntlCatalog,
    delivery_date: giftItem.shippingDetails.deliveryDate.fullDeliveryDate,
    delivery_shipping: giftItem.shippingDetails.shippingMethodName,
    delivery_shipping_fee: giftItem.shippingDetails.shippingPrice,
    delivery_slot: giftItem.shippingDetails.deliveryTimeSlot,
    next_available_date_difference: nextAvailableDateDiff,
    ...cleverTapCartCommonData,
  };

  useEffect(() => {
    removeItemBasedOnKey("selectedDates");
    removeItemBasedOnKey("selectedDate");
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    const payload = {
      pincode: giftItem?.mainproduct?.pincode,
      productId: giftItem?.mainproduct?.productId,
      isInternational,
    };
    dispatch({ type: CHECKOUT_CONSTANTS.GET_SHIPPING_AND_DELIVERY_DATES, payload });
    dispatch({ type: CHECKOUT_CONSTANTS.GET_INTL_SHIPPING, payload });
  }, [dispatch, giftItem.mainproduct, giftItem.mainproduct.pincode, giftItem.mainproduct.productId]);

  /**
   * This method is used to handle close delivery info screen
   *
   */
  const handleClose = () => {
    if (isCartPreviewPage) {
      const popupCloseCleverTapData = {
        area: giftItem.deliveryLocation,
        pincode: undefined,
        city: giftItem.deliveryLocation,
        ...cleverTapData,
      };
      callClevertapEvent(cleverTapCommonEvents.deliveryPopupClosed, popupCloseCleverTapData);
    }
    setOpen(false);
    onClose();
  };

  /**
   * This method is used to handle back to delivery info screen
   *
   */
  function handleBack() {
    if (calenderScreen) {
      setCalenderScreen(false);
      setDeliveryMethodScreen(true);
      setDeliverySlotScreen(false);
    } else if (deliveryMethod) {
      setCalenderScreen(false);
      setDeliverySlotScreen(false);
      setDeliveryMethodScreen(false);
      handleClose();
    } else {
      setCalenderScreen(true);
      setDeliveryMethodScreen(false);
      setDeliverySlotScreen(false);
    }
  }

  /**
   * This method is used to update the cartItem
   *
   * @param {object} newSelectedSlot .
   * @param {object} selectedDate date.
   */
  const updateCart = (newSelectedSlot, selectedDate) => {
    const data = {
      deliveryDate: dateToDMY(new Date(selectedDate)),
      itemIndex: giftItem?.cartItemIndex,
      shippingMethodId: newSelectedSlot?.shipmentMethodId,
      shippingPrice: newSelectedSlot?.slot?.shippingPrice,
      timeSlotId: newSelectedSlot?.slot?.timeSlotId,
    };
    onSelect(data);
  };

  /**
   * This method is used to select date select
   *
   * @param {object} selectedDate date object.
   */
  async function handleDateSelect(selectedDate) {
    const newSelectedDate = new Date(selectedDate);
    const { isDateToday, isDateTomorrow } = isDateTodayOrTomorrow(newSelectedDate);
    if (isCartPreviewPage) {
      const dateSelectionCleverTapData = {
        is_same_date_selected: selectedDate.getDate() === initialDate.getDate(),
        is_same_delivery_selected: isSameShippingSelected,
        is_same_slot_selected: false,
        area: giftItem.deliveryLocation,
        pincode: undefined,
        city: giftItem.deliveryLocation,
        is_date_today: isDateToday,
        is_date_tomorrow: isDateTomorrow,
        ...cleverTapData,
      };
      setIsSameDateSelected(selectedDate.getDate() === initialDate.getDate());
      callClevertapEvent(cleverTapCommonEvents.deliveryDateSelected, dateSelectionCleverTapData);
    }
    selectedDate?.setHours(0, 0, 0, 0);
    setDate(selectedDate);
    const { productId, pincode } = giftItem?.mainproduct;
    const requestParams = {
      productId,
      pinCode: pincode,
      deliveryDate: dateToDMY(selectedDate, "/"),
      countryGeoId: giftItem?.countryGeoId,
    };
    const isInternational = checkIsInternationalProduct(giftItem?.mainproduct);
    dispatch({ type: CHECKOUT_CONSTANTS.SHIPPING_DETAILS_FETCH_START });
    const shippingResponse = await getShippingDetails(requestParams, isInternational);
    if (shippingResponse?.data?.isShippingDetailsExists) {
      const payload = shippingResponse?.data;
      dispatch({ type: CHECKOUT_CONSTANTS.SET_SHIPPING_DETAILS, payload });
      setDomesticShippingDetails(shippingResponse);
      setCalenderScreen(false);
      setDeliverySlotScreen(true);
    }
  }

  /**
   * This method is used to select time slot
   *
   * @param {object} selectedSlot selected time slot object.
   */
  function handleTimeSlotSelect(selectedSlot) {
    if (isCartPreviewPage) {
      const shippingCleverTapData = {
        is_same_date_selected: false,
        is_same_delivery_selected: selectedSlot.shipmentMethodId === giftItem?.shippingDetails?.shippingMethodId,
        is_same_slot_selected: false,
        area: giftItem.deliveryLocation,
        pincode: undefined,
        city: giftItem.deliveryLocation,
        ...cleverTapData,
      };
      setIsSameShippingSelected(selectedSlot.shipmentMethodId === giftItem?.shippingDetails?.shippingMethodId);
      callClevertapEvent(cleverTapCommonEvents.deliveryShippingSelected, shippingCleverTapData);
    }
    if (selectedSlot.shipmentMethodId === giftItem?.shippingDetails?.shippingMethodId) {
      setUserSelectedDate(giftItem?.shippingDetails?.deliveryDate?.fullDeliveryDate);
    } else {
      setUserSelectedDate(null);
    }
    const payload = {
      pincode: giftItem?.mainproduct?.pincode,
      productId: giftItem?.mainproduct?.productId,
      timeSlotId: selectedSlot?.slot?.timeSlotId,
      shipmentType: selectedSlot?.shipmentMethodId,
    };
    if (deliveryMethod) {
      setDeliveryMethodScreen(false);
      setSelectedMethodByUser(selectedSlot);
      dispatch({ type: CHECKOUT_CONSTANTS.GET_INTERNATIONAL_DELIVERY_DATES, payload });
      setCalenderScreen(true);
    }
    if (deliverySlot) {
      setDeliverySlotScreen(false);
      updateCart(selectedSlot, date);
      handleClose();
    }
  }

  /**
   * Handles header title changes based on screen and delivery method.
   *
   * @returns {string} The appropriate header title.
   */
  function handleHeaderTitle() {
    if (calenderScreen) {
      return textConst.checkout.selectDeliveryDate;
    }
    if (deliveryMethod) {
      return textConst.checkout.selectDeliveryShippingMethod;
    }
    return textConst.checkout.selectDeliveryTimeSlot;
  }

  /**
   * Handles loading of delivery method and delivery dates screen.
   *
   * @returns {boolean} The appropriate loader value.
   */
  function handleLoader() {
    if (calenderScreen) {
      return deliveryDatesLoader;
    }
    if (deliveryMethod) {
      return shippingDatesAndMethodsLoader;
    }
    return false;
  }

  return (
    <div className={`${autoSuggestStyles["deliver-on"]} mt20`}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalFullscreenAnimated
          screen={textConst.checkout.shippingScreen}
          handleHeaderBack={handleBack}
          animated={handleLoader()}
          headerTitle={handleHeaderTitle()}
        >
          {calenderScreen && deliveryDates?.length ? (
            <DeliveryOnCalender
              onSelect={handleDateSelect}
              deliveryDates={deliveryDates}
              productDetail={{
                product: { ...giftItem?.mainproduct, isMultiOrderProduct: false, multiOrderProductDetails: {} },
                priceSurgeDetails,
                productPriceByDate,
              }}
              getPrice={getPrice}
              currencyCode={currencyCode}
              itemDesiredDeliveryDate={userSelectedDate}
            />
          ) : null}
          {(deliverySlot || deliveryMethod) && (
            <SelectDeliveryTimeSlot
              selectedDeliveryDate={date}
              onSelect={handleTimeSlotSelect}
              shippingDetails={shippingDetails}
              isShippingDetailsExists={isShippingDetailsExists}
              currencyCode={currencyCode}
              getPrice={getPrice}
              checkIsInternationalProduct={checkIsInternationalProduct(giftItem?.mainproduct)}
              shippingMethodId={selectedMethodByUser?.shipmentMethodId}
              deliverySlot={deliverySlot}
              deliveryMethod={deliveryMethod}
              cleverTapData={cleverTapData}
              isSameDateSelected={isSameDateSelected}
              selectedMethodNameByUser={selectedMethodByUser.shippingMethodName}
              domesticShippingDetails={domesticShippingDetails?.data?.shippingDetails}
            />
          )}
        </ModalFullscreenAnimated>
      </Modal>
    </div>
  );
}

CheckoutInternationalDeliver.propTypes = {
  onSelect: PropTypes.func.isRequired,
  giftItem: PropTypes.objectOf(PropTypes.any).isRequired,
  getPrice: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  cleverTapCartCommonData: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CheckoutInternationalDeliver;
