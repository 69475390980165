import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import APP_CONSTANTS from "../../../src/action-constants/app-actions";

/**
 * This hook displays wallet Balance for Checkout.
 *
 * @param {number} [totalCartPrice=0] - The total price of the cart. Defaults to 0 if not provided.
 */
export default function useFNPWalletBalanceData(totalCartPrice = 0) {
  const dispatch = useDispatch();
  const loggedInUser = useSelector((state) => state.userDetails?.checkLoginInfo?.loggedin);

  useEffect(() => {
    if (loggedInUser) {
      dispatch({ type: APP_CONSTANTS.SET_WALLET });
    }
    // This hook will only be called on initial render since the last time the wallet was loaded.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser, totalCartPrice]);
}
