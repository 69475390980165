import React, { useState, useEffect, useRef, useCallback } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import ForumIcon from "@material-ui/icons/Forum";
import Account from "@material-ui/icons/AccountCircle";
import FormatListBulletedSharpIcon from "@material-ui/icons/FormatListBulletedSharp";
import { Drawer, Slide } from "@material-ui/core";
import PropTypes from "prop-types";
import classnames from "classnames";
import { connect, useSelector } from "react-redux";
import { useRouter } from "next/router";
import SortIcon from "@material-ui/icons/Sort";
import FilterListIcon from "@material-ui/icons/FilterList";
import dynamic from "next/dynamic";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import { setHashToRoute, RoutePageConstants, getRoutePageName, removeHashFromURL } from "../../../src/utils/common";
import FacetFilters from "../../mobile/plp/facet-filters";
import { saveStickyIndex, setCurrentHashSelected, setPopUpStatus } from "../../../src/actions/user-actions";
import styles from "./styles/mobile-sticky-menu.module.scss";
import { getDefaultSortOptionData } from "../../../src/utils/plp-helper";
import { requestPlpListingOnClient, startProductListSorting } from "../../../src/actions/plp-actions";
import { PLP, FACET_FIELD_NAME, COUNTRIES_CODE } from "../../common/constants";
import { setGA4FilterSelected } from "../../../src/ga/gaEvents";
import callClevertapEvent from "../../../src/analytics/clevertapUtility";
import { cleverTapCommonEvents, cleverTapCommonWords } from "../../../src/analytics/clevertapConstants";

const CategorySortForm = dynamic(() => import("../../mobile/mobile-categories-sort"), {
  ssr: false,
});

const URL_CONSTANTS = {
  HOME_INDEX: 0,
  NEED_TODAY: 1,
  GIFTING_EXPERTS: 2,
  OFFERS: 3,
  ACCOUNT: 4,
  CATEGORIES: 5,
};

const useStyles = makeStyles((theme) => ({
  selected: {
    color: theme.color?.red,
  },
  label: {
    width: "5px",
    height: "5px",
    background: theme.color.red,
    borderRadius: "50%",
  },
  selectedSortLabel: {
    fontSize: "12px",
  },
  filterIconContainer: {
    position: "relative",
  },
  filterBadge: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 15,
    height: 15,
    background: theme.color.red,
    borderRadius: "50%",
    position: "absolute",
    right: -5,
  },
  filterBadgeText: {
    fontSize: 10,
    color: "#fff",
  },
}));

/**
 * This is life cycle method of the bottom sticky page.
 *
 * @param {object} func .
 * @returns {React.Fragment} main jsx for the mobile-sticky-menu page.
 */
const useComponentDidMount = (func) =>
  useEffect(() => {
    func();
  });

/**
 * This is life cycle method of the bottom sticky page.
 *
 * @param {object} func .
 */
const useComponentWillMount = (func) => {
  const willMount = useRef(true);
  if (willMount.current) {
    func();
  }
  useComponentDidMount(() => {
    willMount.current = false;
  });
};

/**
 * This functional component represents the bottom sticky page.
 *
 * @param {object} props is the prop the component receives from the parent component.
 * @returns {React.Fragment} main jsx for the mobile-sticky-menu page.
 */
const BottomSticky = (props) => {
  const classes = useStyles();
  const { index, country, catalogId } = props;
  const router = useRouter();
  const page = getRoutePageName(router.pathname);
  const actionClasses = page === RoutePageConstants.PLP_PAGE ? {} : classes.selected;
  const [prevScrollposition, setPrevScrollposition] = useState(0);
  const [visible, setVisible] = useState(true);
  const [namePosition, setNamePosition] = useState({});
  const [stickyIndex, setStickyIndex] = useState(index === undefined ? 0 : index);
  const url = ["/", "/same-day-delivery-gifts", "", "/gifts-offers-lp", "/account/my-account"];
  const slugList = ["", "same-day-delivery-gifts", "", "gifts-offers-lp", "my-account"];
  const promoCode = ["", "?promo=bottom_sb_need_today", "", "?promo=bottom_sb_offers", "?promo=bottom_sb_my_ac"];
  const [sortClicked, setSortClicked] = useState(false);
  const [isFilterScreen, setFilterScreen] = useState(false);
  const searchOptions = useSelector((store) => get(store, "productsLists.searchOptions.params", {}));
  const appConfigs = useSelector((state) => state.appConfigs);
  const { sortOptions, facetDetailList } = useSelector((state) => state.productsLists);
  const [sortValue, setSortValue] = useState(() => getDefaultSortOptionData(sortOptions, searchOptions?.sortOption));
  const { currentHash } = useSelector((state) => state.userDetails);
  const filterValues = searchOptions?.fq;

  const { fnpPageName } = appConfigs;
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window?.location?.search);
    if (window?.location?.hash === PLP.mFILTER_HASH) {
      setFilterScreen(true);
    }
    if (urlSearchParams.has("selm")) {
      setFilterScreen(true);
      if (urlSearchParams.has("selm")) {
        urlSearchParams.delete("selm");
      }
      router.push(
        router.pathname,
        `${window.location?.pathname}${urlSearchParams.toString() ? `?${urlSearchParams.toString()}#selm` : "#selm"}`,
        {
          shallow: true,
        },
      );
    }
    if (isFilterScreen && window?.location?.hash !== PLP.mFILTER_HASH) {
      setFilterScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  useEffect(() => {
    if (searchOptions?.sortOption) {
      setSortValue((prevVal) => (prevVal !== searchOptions.sortOption ? searchOptions.sortOption : prevVal));
    } else {
      setSortValue(() => getDefaultSortOptionData(sortOptions, searchOptions?.sortOption));
    }
  }, [searchOptions.sortOption, sortOptions]);

  useEffect(() => {
    /**
     * This method handle visible size of page
     */
    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      setPrevScrollposition(currentScrollPosition);
      if (Math.abs(prevScrollposition - currentScrollPosition) > 5) {
        setVisible(prevScrollposition > currentScrollPosition);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollposition]);

  useEffect(() => {
    const pageIndex = slugList.findIndex((item) => item === router.query.slug1);
    setStickyIndex(pageIndex);
  }, [router.query.slug1, slugList]);

  const pageStickyMenuData = {
    page_name: `${router.asPath}`,
    page_type: fnpPageName === "category" ? cleverTapCommonWords.MICROSITE : cleverTapCommonWords.ACCOUNT,
    icon_name: namePosition?.value1,
    icon_position: namePosition?.value2,
  };
  useEffect(() => {
    if (namePosition?.value1) {
      callClevertapEvent(cleverTapCommonEvents.bottomNavigationNavIconClicked, pageStickyMenuData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [namePosition?.value1, namePosition?.value2]);

  /**
   * This method returns visibleClass on scrolling
   *
   * @param {isVisible} isVisible value.
   * @param {pageName} pageName value.
   * @returns {object} - returns class name.
   */
  const getVisibleClassName = (isVisible, pageName) => {
    let className = "";
    if (!isVisible) {
      if (pageName === RoutePageConstants.PLP_PAGE) {
        className = classnames(`${styles.bottomIconsPlp} ${styles.hidden}`);
      } else {
        className = classnames(`${styles.bottomIcons} ${styles.hidden}`);
      }
    } else if (pageName === RoutePageConstants.PLP_PAGE) {
      className = styles.bottomIconsPlp;
    } else {
      className = styles.bottomIcons;
    }
    return className;
  };

  /**
   * Returns an object with icon name and position based on the provided constant.
   *
   * This function maps specific URL constants to their corresponding icon names and positions.
   *
   * @param {string} constant - The URL constant used to determine the icon name and position.
   * @returns {object} An object containing:
   *  - {string} value1 - The icon name associated with the provided constant.
   *  - {number} value2 - The position of the icon associated with the provided constant.
   *
   * If the provided constant does not match any case, an empty object is returned.
   */
  const iconNamePosition = (constant) => {
    let iconNamPosition;
    switch (constant) {
      case URL_CONSTANTS.HOME_INDEX:
        iconNamPosition = { value1: "Home", value2: 0 };
        break;
      case URL_CONSTANTS.NEED_TODAY:
        iconNamPosition = { value1: "Need Today", value2: 1 };
        break;
      case URL_CONSTANTS.GIFTING_EXPERTS:
        iconNamPosition = { value1: "Chat with us", value2: 2 };
        break;
      case URL_CONSTANTS.OFFERS:
        iconNamPosition = { value1: "Offers", value2: 3 };
        break;
      case URL_CONSTANTS.ACCOUNT:
        iconNamPosition = { value1: "Account", value2: 4 };
        break;
      case URL_CONSTANTS.CATEGORIES:
        iconNamPosition = { value1: "Categories", value2: 5 };
        break;
      default:
        iconNamPosition = {};
        break;
    }
    return iconNamPosition;
  };

  /**
   * This method navigates to the desired pages in bottom sticky strip.
   *
   * @param {event} event object.
   * @param {stickyIndexVal} stickyIndexVal object.
   */
  const handleChange = (event, stickyIndexVal) => {
    setStickyIndex(stickyIndexVal);
    props.saveStickyIndex(stickyIndexVal);
    const iconData = iconNamePosition(stickyIndexVal);
    setNamePosition(iconData);
    if (stickyIndexVal === URL_CONSTANTS.GIFTING_EXPERTS) {
      if (window.fcWidget) {
        window.fcWidget?.open();
        window.fcWidget?.show();
      }
    } else if (stickyIndexVal === URL_CONSTANTS.NEED_TODAY && props.country !== COUNTRIES_CODE.INDIA) {
      document.getElementsByClassName("categorySelection")[0].classList.remove("displayNone");
      if (document.getElementsByClassName("header-secondary")[0])
        document.getElementsByClassName("header-secondary")[0].classList.add("displayNone");
      if (page === RoutePageConstants.PLP_PAGE) {
        document.getElementsByClassName("plp-sec")[0].classList.add("displayNone");
      } else document.getElementsByClassName("main")[0].classList.add("displayNone");
    } else {
      const newUrl = url.map((ele, i) => url[i] + promoCode[i]);
      router.push(newUrl[stickyIndexVal]);
    }
  };

  /**
   * This method handle sort.
   *
   * @param {event} event object.
   * @param {newValue} newValue object.
   */
  const handleSortCategory = (event, newValue) => {
    if (newValue === 0) {
      const urlParams = new URLSearchParams(window.location.search);
      router.push(
        router.pathname,
        `${window.location?.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ""}#selm`,
        {
          shallow: true,
        },
      );
      setFilterScreen(true);
      props.setCurrentHashStatus("selm");
    } else if (!sortClicked) {
      setHashToRoute("sortm");
      setSortClicked(true);
      props.setCurrentHashStatus("sortm");
    }
  };

  /**
   * The method is a helper function to generate combination of all the slugs
   *
   *
   * @param {string} newUrl URL
   */
  const handleRouteChange = useCallback(() => {
    if (sortClicked) {
      return setSortClicked(false);
    }
    if (isFilterScreen) {
      return setFilterScreen(false);
    }
    if (currentHash === "selm" || currentHash === "sortm") {
      return props.setPopUpStatus(true);
    }
    return false;
  }, [isFilterScreen, props, sortClicked, currentHash]);

  /**
   * this function calls the api for product list
   *
   * @param {object} urlParams current url params
   */
  const handleProductListCall = (urlParams) => {
    const searchOptionsParams = { params: { ...searchOptions, page: 0 } };
    if (urlParams.has("sortOption")) {
      searchOptionsParams.params.sortOption = decodeURI(urlParams.get("sortOption"));
    } else {
      delete searchOptionsParams.params.sortOption;
    }
    props.startProductListSorting();
    props.requestPlpListingOnClient(searchOptionsParams);
  };

  /**
   * This method handle sort function
   *
   * @param {event} event object.
   * @param {newValue} newValue object.
   */
  const handleSortChange = (event, newValue) => {
    const newSortLabel = sortOptions?.find((option) => option.value === newValue)?.level;

    const pageData = {
      page_name: `${router.asPath}`,
      page_type: cleverTapCommonWords.PLP,
      updated_sort: newSortLabel,
    };
    setGA4FilterSelected(newValue);
    setSortValue(newValue);
    const sortOption = newValue;
    const urlParams = new URLSearchParams(window?.location?.search);
    if (urlParams.has("sortOption")) {
      urlParams.set("sortOption", sortOption);
    } else {
      urlParams.append("sortOption", sortOption);
    }
    router.replace(
      router.pathname,
      `${window.location?.pathname}${urlParams.toString() ? `?${urlParams.toString()}` : ""}`,
      { shallow: true },
    );
    props.setPopUpStatus(false);
    setSortClicked(false);
    handleProductListCall(new URLSearchParams(`?${urlParams.toString()}`));
    window.scrollTo(0, 0);
    callClevertapEvent(cleverTapCommonEvents.sortSelected, pageData);
  };

  useComponentWillMount(() => {
    if (props.stickyIndex === undefined) props.saveStickyIndex(stickyIndex);
  });

  useEffect(() => {
    router.events.on("hashChangeStart", handleRouteChange);

    return () => {
      router.events.off("hashChangeStart", handleRouteChange);
    };
  }, [handleRouteChange, router.events]);

  /**
   * Function used to close sort modal
   */
  const closeSortModal = useCallback(() => {
    router.back();
  }, [router]);

  const sortSelectedLabel = (
    <>
      <div>Sort</div>
      <div className={classes.selectedSortLabel}>
        {sortOptions?.find((option) => option.value === sortValue)?.level}
      </div>
    </>
  );
  const sortIconJSX = (
    <>
      <span className={`${searchOptions?.sortOption ? classes.label : ""}`} />
      <SortIcon />
    </>
  );

  const filterIcon = (
    <>
      <div className={classes.filterIconContainer}>
        {searchOptions?.fq && (
          <span className={classes.filterBadge}>
            <p className={classes.filterBadgeText}>{searchOptions.fq.length}</p>
          </span>
        )}
        <FilterListIcon />
      </div>
    </>
  );

  /**
   * PLP Sticky Menu JSX
   *
   */
  const handleFilterClose = () => {
    router.back();
  };

  /**
   *
   */
  const closeFilterScreen = () => {
    router.push(router.pathname, `${removeHashFromURL(window.location.href)}`, {
      shallow: true,
    });
    setFilterScreen(false);
    if (typeof window !== "undefined") {
      const toastState = sessionStorage.getItem("suppressionToastState");
      if (!toastState && filterValues?.length) {
        window.sessionStorage.setItem("suppressionToastState", true);
      }
    }
  };

  /**
   * PLP Sticky Menu JSX
   *
   * @param {string} pageName based on page name menu will be shown
   *
   * @returns {React.ReactElement | null} returns either JSX or Null
   */
  const PLPStickyMenuJSX = (pageName) => {
    if (pageName === RoutePageConstants.PLP_PAGE && fnpPageName === "category") {
      return (
        <div className={styles.categoriesSort} id="plpFilter">
          <div className={`${styles.bottomIconsPlp} ${styles.catSort}`}>
            <BottomNavigation showLabels onChange={handleSortCategory}>
              {facetDetailList?.some(
                (facet) => facet?.facetFieldName !== FACET_FIELD_NAME.PRODUCT_TYPE_TAGS_RELATION,
              ) && (
                <BottomNavigationAction
                  disableRipple
                  classes={actionClasses}
                  label="Filter"
                  data-btn-type="filters"
                  icon={filterIcon}
                />
              )}
              <BottomNavigationAction
                data-btn-type="sort"
                disableRipple
                classes={actionClasses}
                label={sortSelectedLabel}
                icon={sortIconJSX}
              />
            </BottomNavigation>

            <Drawer anchor="bottom" transitionDuration={{ enter: 0 }} open={sortClicked} onClose={closeSortModal}>
              <div className={styles.sortPopUp}>
                <CategorySortForm
                  sortValue={sortValue}
                  handleSortChange={handleSortChange}
                  closeSortModal={closeSortModal}
                />
              </div>
            </Drawer>
            <Slide direction="up" in={isFilterScreen} mountOnEnter unmountOnExit>
              <div className={styles.categoryContainer}>
                <FacetFilters
                  open={isFilterScreen}
                  handleCloseScreen={closeFilterScreen}
                  handleFilterClose={handleFilterClose}
                />
              </div>
            </Slide>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {catalogId === "india" && !(page === RoutePageConstants.PLP_PAGE && fnpPageName === "category") && (
        <div className={getVisibleClassName(visible, page)} id="stickyMenu">
          <BottomNavigation value={stickyIndex} onChange={handleChange} showLabels>
            <BottomNavigationAction label="Home" icon={<HomeIcon />} />
            {country === COUNTRIES_CODE.INDIA ? (
              <BottomNavigationAction className={styles.chatLabel} label="Same Day" icon={<QueryBuilderIcon />} />
            ) : (
              <BottomNavigationAction label="Categories" icon={<FormatListBulletedSharpIcon />} />
            )}
            <BottomNavigationAction className={styles.chatLabel} label="Chat with us" icon={<ForumIcon />} />
            <BottomNavigationAction label="Offers" icon={<LocalOfferIcon />} />
            <BottomNavigationAction label="Account" icon={<Account />} />
          </BottomNavigation>
        </div>
      )}
      {country === COUNTRIES_CODE.INDIA ? PLPStickyMenuJSX(page) : null}
    </>
  );
};

/**
 * mapping mobile sticky state to props
 *
 * @param {*} state - returns root geoId and user detail
 * @returns {void} - returns nothing
 */
const mapStateToProps = (state) => {
  return {
    country: state.appConfigs.rootGeoId,
    index: state.userDetails.index,
    catalogId: state.appConfigs.catalogId,
  };
};

/**
 * mapping mobile sticky mapDispatchToProps
 *
 * @param {dispatch} dispatch .
 * @returns {props} return prop.
 */
const mapDispatchToProps = (dispatch) => ({
  saveStickyIndex: (stickyIndex) => dispatch(saveStickyIndex(stickyIndex)),
  startProductListSorting: () => dispatch(startProductListSorting()),
  requestPlpListingOnClient: (searchOptionsParams) =>
    dispatch(requestPlpListingOnClient({ options: searchOptionsParams, appendProducts: false })),
  setPopUpStatus: (flag) => dispatch(setPopUpStatus(flag)),
  setCurrentHashStatus: (hashVal) => dispatch(setCurrentHashSelected(hashVal)),
});

BottomSticky.propTypes = {
  stickyIndex: PropTypes.number,
  index: PropTypes.number,
  saveStickyIndex: PropTypes.func.isRequired,
  country: PropTypes.string,
  catalogId: PropTypes.string,
  startProductListSorting: PropTypes.func.isRequired,
  requestPlpListingOnClient: PropTypes.func.isRequired,
  setPopUpStatus: PropTypes.func.isRequired,
  setCurrentHashStatus: PropTypes.func.isRequired,
};
BottomSticky.defaultProps = {
  stickyIndex: 0,
  index: 0,
  country: "",
  catalogId: "",
};
export default connect(mapStateToProps, mapDispatchToProps)(BottomSticky);
