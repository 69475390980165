import { cleverTapCommonEvents } from "../analytics/clevertapConstants";
import callClevertapEvent from "../analytics/clevertapUtility";

/**
 * Function to check that cdp exists or not
 *
 * @returns {boolean} returns true or false
 */
function checkCDPExist() {
  return !!(typeof window !== "undefined" && window.cdp);
}
/**
 * CDP event for click on image
 *
 * @param {string} imageUrl url of the image
 */
export function cdpGetImagehover(imageUrl) {
  if (checkCDPExist()) {
    window.cdp.getImagehover(imageUrl);
  }
}
/**
 * CDP event for tracking widget loaded called in You may also like section.
 *
 */
export function cdpWidget() {
  if (checkCDPExist()) {
    window.cdp.widgetPLP();
  }
}
/**
 * CDP event for tracking widget loaded in PLP
 *
 */
export function cdpWidgetPLP() {
  if (checkCDPExist() && window?.cdp?.widgetPLP) {
    window.cdp.widgetPLP();
  }
}
/**
 * CDP event for tracking widget loaded in PLP
 *
 * @param {HTMLElement} elem clicked elemnet
 * @param {string} pageName this the page type where this event trigger
 * @param {string} bannerId banner data id
 */
export function cdpBannerClick(elem, pageName, bannerId) {
  const bannerEvent = {
    bannerIdclick: bannerId,
  };
  callClevertapEvent(cleverTapCommonEvents.bannerClicked, bannerEvent);
  if (checkCDPExist() && window?.cdp?.bannerClick) {
    window.cdp.bannerClick(elem, pageName, bannerId);
  }
}
/**
 * CDP event for tracking click called in You may also like section.
 *
 */
export function cdpClick() {
  if (checkCDPExist()) {
    window.cdp.click();
  }
}
/**
 * CDP event for tracking click on description tags.
 *
 */
export function cdpDescription() {
  if (checkCDPExist()) {
    window.cdp.description();
  }
}
/**
 * CDP event for tracking pincode /address change.
 *
 * @param {string} address is  the selected address from the pin code popup
 * @param {boolean} avail is product available for the location
 * @param {string} pid product id of the selected product
 * @param {string} area user selected area
 */
export function cdpGetLocation(address, avail, pid, area) {
  if (checkCDPExist()) {
    window.cdp.getLocation(address, avail, pid, area);
  }
}
/**
 * CDP event for tracking variant change
 *
 * @param {boolean} a boolean value as param
 * @param {object} b object as param
 * @param {boolean} c boolean value as param
 */
export function cdpVariantChange(a = null, b = null, c = null) {
  if (checkCDPExist()) {
    window.cdp.variantChange(a, b, c);
  }
}
/**
 * CDP event for tracking load change for  variant change
 *
 */
export function cdpLoad() {
  if (checkCDPExist()) {
    window.cdp.load();
  }
}
/**
 * CDP event for tracking click on add to cart or Buy now.
 *
 * @param {boolean} cdpIsMessageAvailable If message is available on product its value is true else false
 * @param {string} cdpSelectedDate The selected date for the product
 * @param {string} shippingMethodName The selected shipping method
 * @param {string} cdpTime The start and end time of shipping method selected
 */
export function cdpAddCart(cdpIsMessageAvailable, cdpSelectedDate, shippingMethodName, cdpTime) {
  if (checkCDPExist()) {
    window.cdp.addCart(cdpIsMessageAvailable, cdpSelectedDate, shippingMethodName, cdpTime);
  }
}
/**
 * CDP event for tracking click on add to cart on PLP
 *
 * @param {boolean} productDetail product details
 */
export function cdpAddCartPLP(productDetail) {
  if (checkCDPExist()) {
    window.cdp.callToApi({ productDetail }, "addtocartplp");
  }
}
/**
 * CDP event for change in shipping method changes and time slot.
 *
 * @param {string} startDate start date of shipping method
 * @param {string} noOfDays number of days
 * @param {string} shippingType shipping method type
 * @param {string} shippingPrice shipping method price
 * @param {string} timeSlot selected time slot
 */
export function callCdpDelivery(startDate = null, noOfDays = 1, shippingType = "", shippingPrice = "", timeSlot = "") {
  if (checkCDPExist()) {
    window.cdp.delivery(startDate, noOfDays, shippingType, shippingPrice, timeSlot);
  }
}

/**
 * CDP event for change in shipping method changes and time slot.
 *
 * @param {string} from from where addon is triggered.
 * @param {string} productFor product Id.
 */
export function callCdpAddonLoad(from = "", productFor = "") {
  if (checkCDPExist()) {
    window.cdp.addonload(from, productFor);
  }
}

/**
 * CDP event for change in shipping method changes and time slot.
 *
 * @param {object} data from where addon is triggered.
 */
export function callCdpAddon(data) {
  if (checkCDPExist()) {
    window.cdp.addon(data);
  }
}

/**
 * CDP event for getting click detail.
 *
 * @param {object} index from where addon is triggered.
 * @param {object} imageUrl from where addon is triggered.
 */
export function callCdpGetClickDetail(index, imageUrl) {
  if (checkCDPExist()) {
    window.cdp.getClickDetail(index, imageUrl);
  }
}

/**
 * CDP event for getting image slide detail.
 *
 * @param {object} index index of image.
 * @param {object} imageUrl imageUrl.
 */
export function callCdpGetSlideDetail(index, imageUrl) {
  if (checkCDPExist()) {
    window.cdp.getSlideDetail(index, imageUrl);
  }
}
