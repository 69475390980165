import React, { useRef } from "react";
import { Box, Link, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";

import styles from "./styles/fnp-logo.module.scss";
import callClevertapEvent, { getPageNameForCleverTap } from "../../../../src/analytics/clevertapUtility";
import { cleverTapCommonEvents } from "../../../../src/analytics/clevertapConstants";

const useStyles = makeStyles(() => ({
  homePagelogo: {
    position: "relative",
    top: "8px",
    left: "-5px",
  },
  logoWrapper: {
    width: "190px",
  },
}));

/**
 * This component is used to show fnp logo in the m-site header
 *
 * @param {object} root0 props from parent
 * @param {string} root0.catalogId current catalog id
 *
 * @returns {React.ReactElement} - renders fnp-logo component
 */
const FNPLogo = ({ catalogId }) => {
  const classes = useStyles();
  const clickedRef = useRef(false);

  let homeURL;
  if (catalogId === "india") {
    homeURL = `/`;
  } else {
    homeURL = catalogId === "singapore" ? `/${catalogId}/rakhi` : `/${catalogId}/gifts`;
  }

  /**
   * on click event for fnp logo
   */
  const handleLogoOnClick = () => {
    if (clickedRef.current) return;
    clickedRef.current = true;
    const pageHeaderMenuData = {
      page_name: getPageNameForCleverTap(),
      page_type: getPageNameForCleverTap(),
      menu_name: "FNP",
    };
    callClevertapEvent(cleverTapCommonEvents.headerMenuClicked, pageHeaderMenuData);
  };

  return (
    <>
      <div className={classes.logoWrapper}>
        <Link href={homeURL} className={classes.homePagelogo} onClick={handleLogoOnClick}>
          <Box component="span" className={styles.logoSvg} />
        </Link>
      </div>
    </>
  );
};

FNPLogo.propTypes = {
  catalogId: PropTypes.string,
};

FNPLogo.defaultProps = {
  catalogId: "india",
};

export default FNPLogo;
