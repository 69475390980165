const CheckoutPageConstants = {
  INITIATE_CHECKOUT: "INITIATE_CHECKOUT",
  INITIATE_CHECKOUT_SUCCESS: "INITIATE_CHECKOUT_SUCCESS",
  INITIATE_CHECKOUT_FAILED: "INITIATE_CHECKOUT_FAILED",
  INITIATE_CHECKOUT_UPDATED_CART: "INITIATE_CHECKOUT_UPDATED_CART",
  INITIATE_CHECKOUT_UPDATED_CART_SUCCESS: "INITIATE_CHECKOUT_UPDATED_CART_SUCCESS",
  INITIATE_CHECKOUT_UPDATED_CART_FAILED: "INITIATE_CHECKOUT_UPDATED_CART_FAILED",
  FREQUENTLY_BOUGHT_ADDONS_SUCCESS: "FREQUENTLY_BOUGHT_ADDONS_SUCCESS",
  FETCH_MESSAGE_CARD_MOBILE: "FETCH_MESSAGE_CARD_MOBILE",
  FETCH_MESSAGE_CARD_MOBILE_SUCCESS: "FETCH_MESSAGE_CARD_MOBILE_SUCCESS",
  FETCH_MESSAGE_CARD_MOBILE_FAILED: "FETCH_MESSAGE_CARD_MOBILE_FAILED",
  SET_ITEM_ATTRIBUTES_MOBILE: "SET_ITEM_ATTRIBUTES_MOBILE",
  SET_ITEM_ATTRIBUTES_MOBILE_SUCCESS: "SET_ITEM_ATTRIBUTES_MOBILE_SUCCESS",
  SET_ITEM_ATTRIBUTES_MOBILE_FAILED: "SET_ITEM_ATTRIBUTES_MOBILE_FAILED",
  UPDATE_ADDON_QTY: "UPDATE_ADDON_QTY",
  UPDATE_ADDON_QTY_SUCCESS: "UPDATE_ADDON_QTY_SUCCESS",
  UPDATE_ADDON_QTY_FAILED: "UPDATE_ADDON_QTY_FAILED",
  APPLY_COUPON: "APPLY_COUPON",
  APPLY_COUPON_SUCCESS: "APPLY_COUPON_SUCCESS",
  APPLY_COUPON_FAILED: "APPLY_COUPON_FAILED",
  REMOVE_COUPON: "REMOVE_COUPON",
  REMOVE_COUPON_SUCCESS: "REMOVE_COUPON_SUCCESS",
  REMOVE_COUPON_FAILED: "REMOVE_COUPON_FAILED",
  FETCH_COUPONS: "FETCH_COUPONS",
  FETCH_COUPONS_SUCCESS: "FETCH_COUPONS_SUCCESS",
  FETCH_COUPONS_FAILED: "FETCH_COUPONS_FAILED",
  FETCH_CART_EXPIRY: "FETCH_CART_EXPIRY",
  FETCH_CART_EXPIRY_SUCCESS: "FETCH_CART_EXPIRY_SUCCESS",
  FETCH_CART_EXPIRY_FAILED: "FETCH_CART_EXPIRY_FAILED",
  FETCH_COMPLETE_CART: "FETCH_COMPLETE_CART",
  FETCH_COMPLETE_CART_SUCCESS: "FETCH_COMPLETE_CART_SUCCESS",
  FETCH_COMPLETE_CART_FAILED: "FETCH_COMPLETE_CART_FAILED",
  FETCH_CART_SUMMARY_AMOUNT: "FETCH_CART_SUMMARY_AMOUNT",
  FETCH_CART_SUMMARY_AMOUNT_SUCCESS: "FETCH_CART_SUMMARY_AMOUNT_SUCCESS",
  FETCH_CART_SUMMARY_AMOUNT_FAILED: "FETCH_CART_SUMMARY_AMOUNT_FAILED",
  FETCH_VIEW_CART: "FETCH_VIEW_CART",
  FETCH_VIEW_CART_SUCCESS: "FETCH_VIEW_CART_SUCCESS",
  FETCH_VIEW_CART_FAILED: "FETCH_VIEW_CART_FAILED",
  ADD_ADDON_TO_CART: "ADD_ADDON_TO_CART",
  ADD_ADDON_TO_CART_SUCCESS: "ADD_ADDON_TO_CART_SUCCESS",
  ADD_ADDON_TO_CART_FAILED: "ADD_ADDON_TO_CART_FAILED",
  DELETE_ITEM: "DELETE_ITEM",
  DELETE_ITEM_SUCCESS: "DELETE_ITEM_SUCCESS",
  DELETE_ITEM_FAILED: "DELETE_ITEM_FAILED",
  GET_CARD_MESSAGE_DETAILS: "GET_CARD_MESSAGE_DETAILS",
  GET_CARD_MESSAGE_DETAILS_SUCCESS: "GET_CARD_MESSAGE_DETAILS_SUCCESS",
  GET_CARD_MESSAGE_DETAILS_FAILED: "GET_CARD_MESSAGE_DETAILS_FAILED",
  GET_SALUTATIONS: "GET_SALUTATIONS",
  GET_SALUTATIONS_SUCCESS: "GET_SALUTATIONS_SUCCESS",
  GET_SALUTATIONS_FAILED: "GET_SALUTATIONS_FAILED",
  INITIATE_ADD_NEW_ADDRESS: "INITIATE_ADD_NEW_ADDRESS",
  INITIATE_ADD_NEW_ADDRESS_SUCCESS: "INITIATE_ADD_NEW_ADDRESS_SUCCESS",
  INITIATE_ADD_NEW_ADDRESS_FAILED: "INITIATE_ADD_NEW_ADDRESS_FAILED",
  CART_ITEM_EXPIRED: "CART_ITEM_EXPIRED",
  UPDATE_CART_FROM_CHECKOUT_PAGE: "UPDATE_CART_FROM_CHECKOUT_PAGE",
  UPDATE_CART_FROM_CHECKOUT_PAGE_SUCCESS: "UPDATE_CART_FROM_CHECKOUT_PAGE_SUCCESS",
  UPDATE_CART_FROM_CHECKOUT_PAGE_FAILED: "UPDATE_CART_FROM_CHECKOUT_PAGE_FAILED",
  SET_ITEM_ATTRIBUTES_FOR_ORDER: "SET_ITEM_ATTRIBUTES_FOR_ORDER",
  SET_ITEM_ATTRIBUTES_FOR_ORDER_SUCCESS: "SET_ITEM_ATTRIBUTES_FOR_ORDER_SUCCESS",
  SET_ITEM_ATTRIBUTES_FOR_ORDER_FAILED: "SET_ITEM_ATTRIBUTES_FOR_ORDER_FAILED",
  SET_CHECKOUT_PAGE_DATA: "SET_CHECKOUT_PAGE_DATA",
  SET_ADDRESS_FOR_ITEM: "SET_ADDRESS_FOR_ITEM",
  SET_ADDRESS_FOR_ITEM_SUCCESS: "SET_ADDRESS_FOR_ITEM_SUCCESS",
  SET_ADDRESS_FOR_ITEM_FAILED: "SET_ADDRESS_FOR_ITEM_FAILED",
  APPLY_MANUAL_ADJUSTMENT: "APPLY_MANUAL_ADJUSTMENT",
  APPLY_MANUAL_ADJUSTMENT_SUCCESS: "APPLY_MANUAL_ADJUSTMENT_SUCCESS",
  APPLY_MANUAL_ADJUSTMENT_FAILED: "APPLY_MANUAL_ADJUSTMENT_FAILED",
  REMOVE_MANUAL_ADJUSTMENT: "REMOVE_MANUAL_ADJUSTMENT",
  REMOVE_MANUAL_ADJUSTMENT_SUCCESS: "REMOVE_MANUAL_ADJUSTMENT_SUCCESS",
  REMOVE_MANUAL_ADJUSTMENT_FAILED: "REMOVE_MANUAL_ADJUSTMENT_FAILED",
  UPDATE_SENDER_INFO: "UPDATE_SENDER_INFO",
  SET_SHOW_COUPON_INPUT: "SET_SHOW_COUPON_INPUT",
  SET_ADDRESS_DETAILS: "SET_ADDRESS_DETAILS",
  SET_IS_EDIT_ADDRESS: "SET_IS_EDIT_ADDRESS",
  DELETE_ITEM_ADDRESS: "DELETE_ITEM_ADDRESS",
  DELETE_ITEM_ADDRESS_SUCCESS: "DELETE_ITEM_ADDRESS_SUCCESS",
  DELETE_ITEM_ADDRESS_FAILED: "DELETE_ITEM_ADDRESS_FAILED",
  SET_GIFT_ITEM_DEFAULT_ADDRESS: "SET_GIFT_ITEM_DEFAULT_ADDRESS",
  CLEAR_COUPON_ERROR: "CLEAR_COUPON_ERROR",
  UPDATE_SENDER_CONTACT_NUMBER: "UPDATE_SENDER_CONTACT_NUMBER",
  SET_UPDATE_ADDRESS_CART_INDEX: "SET_UPDATE_ADDRESS_CART_INDEX",
  CLEAR_CART_UPDATE_DATA: "CLEAR_CART_UPDATE_DATA",
  GET_SPL_COUNTRY_GEO_IDS: "GET_SPL_COUNTRY_GEO_IDS",
  GET_SPL_COUNTRY_GEO_IDS_SUCCESS: "GET_SPL_COUNTRY_GEO_IDS_SUCCESS",
  GET_SPL_COUNTRY_GEO_IDS_FAILED: "GET_SPL_COUNTRY_GEO_IDS_FAILED",
  GET_VENDOR_URLS: "GET_VENDOR_URLS",
  GET_VENDOR_URLS_SUCCESS: "GET_VENDOR_URLS_SUCCESS",
  GET_VENDOR_URLS_FAILED: "GET_VENDOR_URLS_FAILED",
  GET_SHIPPING_AND_DELIVERY_DATES: "GET_SHIPPING_AND_DELIVERY_DATES",
  GET_SHIPPING_AND_DELIVERY_DATES_SUCCESS: "GET_SHIPPING_AND_DELIVERY_DATES_SUCCESS",
  GET_SHIPPING_AND_DELIVERY_DATES_FAILED: "GET_SHIPPING_AND_DELIVERY_DATES_FAILED",
  SHIPPING_AND_DELIVERY_DATES_FETCH_START: "SHIPPING_AND_DELIVERY_DATES_FETCH_START",
  GET_INTERNATIONAL_DELIVERY_DATES_FETCH_START: "GET_INTERNATIONAL_DELIVERY_DATES_FETCH_START",
  SHIPPING_DETAILS_FETCH_START: "SHIPPING_DETAILS_FETCH_START",
  SHIPPING_DETAILS_FETCH_ERROR: "SHIPPING_DETAILS_FETCH_ERROR",
  SET_SHIPPING_DETAILS: "SET_SHIPPING_DETAILS",
  GET_INTL_SHIPPING: "GET_INTL_SHIPPING",
  GET_INTL_SHIPPING_SUCCESS: "GET_INTL_SHIPPING_SUCCESS",
  GET_INTL_SHIPPING_FAILED: "GET_INTL_SHIPPING_FAILED",
  GET_INTERNATIONAL_DELIVERY_DATES: "GET_INTERNATIONAL_DELIVERY_DATES",
  GET_INTERNATIONAL_DELIVERY_DATES_SUCCESS: "GET_INTERNATIONAL_DELIVERY_DATES_SUCCESS",
  GET_INTERNATIONAL_DELIVERY_DATES_FAILED: "GET_INTERNATIONAL_DELIVERY_DATES_FAILED",
  COUPON_APPLIED: "COUPON_APPLIED",
  UPDATE_SENDER_MOBILE_COUNTRY_CODE: "UPDATE_SENDER_MOBILE_COUNTRY_CODE",
  UPDATE_SENDER_MOBILE_COUNTRY_CODE_LABEL: "UPDATE_SENDER_MOBILE_COUNTRY_CODE_LABEL",
  VALIDATE_ADDRESS_FORM: "VALIDATE_ADDRESS_FORM",
  SET_LOGIN_REDIRECTION: "SET_LOGIN_REDIRECTION",
  SET_SHIPPING_INSTRUCTIONS: "SET_SHIPPING_INSTRUCTIONS",
  SET_SHIPPING_INSTRUCTIONS_SUCCESS: "SET_SHIPPING_INSTRUCTIONS_SUCCESS",
  SET_SURGE_PRICE_DETAILS: "SET_SURGE_PRICE_DETAILS",
  GET_SURGE_PRICE_DETAILS: "GET_SURGE_PRICE_DETAILS",
  GET_SURGE_PRICE_DETAILS_FAILED: "GET_SURGE_PRICE_DETAILS_FAILED",
  GET_TNC_DETAILS: "GET_TNC_DETAILS",
  DONATION_CHANGE: "DONATION_CHANGE",
  DONATION_CHANGE_SUCCESS: "DONATION_CHANGE_SUCCESS",
  DONATION_CHANGE_FAILED: "DONATION_CHANGE_FAILED",
  DONATION_INFO: "DONATION_INFO",
  DONATION_INFO_SUCCESS: "DONATION_INFO_SUCCESS",
  DONATION_INFO_FAILED: "DONATION_INFO_FAILED",
  SET_ADDONS_NAMES: "SET_ADDONS_NAMES",
};

export const CheckoutRoutes = {
  CHECKOUT: "checkout",
  ADD_NEW_ADDRESS: "add-delivery",
  CHANGE_DELIVERY: "change-delivery",
  MESSAGE_ON_CARD: "message-on-card",
  APPLY_COUPON: "apply-coupon",
  PAYMENT_OPTIONS: "payment-options",
  DELIVERY_ADDRESS: "delivery-address",
  EDIT_DELIVERY: "edit-delivery",
  LOGIN: "login",
  CREDIT_CARD: "credit-card",
  DEBIT_CARD: "debit-card",
  NET_BANKING: "net-banking",
  UPI: "upi",
  PAYTM: "paytm",
  PHONEPE: "phonepe",
  WALLETS: "wallet",
  PAYPAL: "paypal",
  EMI: "emi",
  EMI_PAY: "emi-pay",
  SAVED_CARD: "saved-cards",
  PAYMENT_FAILED: "payment-not-confirmed",
  ORDER_CONFIRMATION: "order-confirmation",
  RETRY_PAYMENT: "retry-payment",
  SELECT_CITY: "select-city",
  GOOGLE_PAY: "googlepay",
  WALLET_DETAILS: "wallet-details",
};

const firstPageText = "1/3";
const secondPageText = "2/3";
const thirdPageText = "3/3";

export const checkoutHeaders = {
  [CheckoutRoutes.LOGIN]: {
    label: "Login / Register",
    paginationText: firstPageText,
  },
  [CheckoutRoutes.DELIVERY_ADDRESS]: {
    label: "Order & Delivery Details",
    paginationText: secondPageText,
  },
  [CheckoutRoutes.MESSAGE_ON_CARD]: {
    label: "FREE Message Card",
    paginationText: secondPageText,
  },
  [CheckoutRoutes.ADD_NEW_ADDRESS]: {
    label: "Add New Address",
    paginationText: secondPageText,
  },
  [CheckoutRoutes.EDIT_DELIVERY]: {
    label: "Edit Delivery Address",
    paginationText: secondPageText,
  },
  [CheckoutRoutes.SELECT_CITY]: {
    label: "Select City",
    paginationText: secondPageText,
  },
  [CheckoutRoutes.CHANGE_DELIVERY]: {
    label: "Saved Addresses",
    paginationText: secondPageText,
  },
  [CheckoutRoutes.APPLY_COUPON]: {
    label: "Apply Coupons",
    paginationText: secondPageText,
  },
  [CheckoutRoutes.PAYMENT_OPTIONS]: {
    label: "Payment Options",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.RETRY_PAYMENT]: {
    label: "Payment Options",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.CREDIT_CARD]: {
    label: "Credit Card",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.DEBIT_CARD]: {
    label: "Debit Card",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.NET_BANKING]: {
    label: "Net Banking",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.UPI]: {
    label: "UPI",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.PAYTM]: {
    label: "Paytm",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.PHONEPE]: {
    label: "PhonePe",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.WALLETS]: {
    label: "Wallets",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.PAYPAL]: {
    label: "PayPal",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.EMI]: {
    label: "EMI",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.EMI_PAY]: {
    label: "Credit Card",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.SAVED_CARD]: {
    label: "Saved Cards",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.GOOGLE_PAY]: {
    label: "GooglePay",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.PAYMENT_FAILED]: {
    label: "Payment",
    paginationText: thirdPageText,
  },
  [CheckoutRoutes.ORDER_CONFIRMATION]: {
    label: "Thank you",
    paginationText: "",
  },
};

export const payPalUrl = {
  APPROVE_URL: `/control/payPalNotifyRest`,
  CANCEL_URL: "/control/payPalCancelNew",
};

export default CheckoutPageConstants;
