import React, { useState, useEffect, useRef } from "react";
import { Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import styles from "./styles/wallet-CheckBox.module.scss";
import APP_CONSTANTS from "../../src/action-constants/app-actions";
import { textConst } from "../../const/text-english";

/**
 * This component represents the tooltip for wallet usable balance when hover i button.
 *
 * @returns {React.ReactElement} jsx for the current wallet usable balance.
 */
const UsableCashTooltip = () => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipRef = useRef(null);
  const usableBalance = useSelector((state) => state.walletDetails?.walletUsableBalance?.usableBalance) || 0;
  const walletUsableBalanceTnC = useSelector((state) => state.walletDetails?.walletUsableBalanceTnC);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: APP_CONSTANTS.SET_WALLET_USABLE_BALANCE_TNC });
  }, [dispatch, walletUsableBalanceTnC]);

  /**
   * Handles the touch start event on a mobile device.
   *
   * @param {TouchEvent} event - The touch event triggered by user interaction.
   * @returns {void}
   */
  const handleTouchStart = (event) => {
    event.stopPropagation();
    setIsTooltipVisible(true);
  };

  /**
   * Detects a click or touch event outside of the tooltip element and closes the tooltip.
   *
   * @param {TouchEvent} event - The event triggered by a click or touch outside the tooltip.
   * @returns {void}
   */
  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setIsTooltipVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.usableBalance} ref={tooltipRef}>
      <img
        className={styles.iBtn}
        src="/assets/images/info-circle.svg"
        alt="info_icon"
        onTouchStart={handleTouchStart}
      />
      <Typography>
        {textConst.payment.wallet.usableBalanceForOrder}
        {usableBalance}
      </Typography>
      <div className={`${styles.tooltipText} ${isTooltipVisible ? styles.visible : ""}`}>
        <header>{textConst.payment.wallet.fnpCashTnC}</header>
        <div className={styles.tooltipBody}>{HTMLReactParser(walletUsableBalanceTnC)}</div>
      </div>
    </div>
  );
};

export default UsableCashTooltip;
