import React from "react";
import { makeStyles } from "@material-ui/styles";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  addonsQtyBlock: {
    display: "inline-flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: 4,
    marginLeft: 10,
    verticalAlign: "sub",
    padding: 0,
  },
  dAddonsQtyBlock: {
    marginLeft: "auto",
  },
  qtyDecrease: {
    padding: 2,
    color: "#222",
    backgroundColor: "#f8f8f8",
    height: 22,
    width: 23,
    borderRight: "1px solid #ccc",
    borderRadius: "4px 0 0 4px",
    cursor: "pointer",
  },
  qtyIncrease: {
    padding: 2,
    color: "#222",
    backgroundColor: "#f8f8f8",
    fontSize: 18,
    height: 22,
    width: 23,
    borderLeft: "1px solid #ccc",
    borderRadius: "0 4px 4px 0",
    cursor: "pointer",
  },
  qtyUpdateDisable: {
    color: "#eaeaea",
    pointerEvents: "none",
  },
  addonQtyValue: { fontSize: 14, padding: "2px 8px", color: "#222", display: "inline-block", verticalAlign: "bottom" },
});

/**
 * Component to render Update Quantity UI
 *
 * @param {object} props - Component properties.
 * @param {number} props.quantity - The current quantity of the item.
 * @param {number} props.itemIndex - The index of the item in the cart.
 * @param {string} props.productId - The ID of the product.
 * @param {Function} props.handleUpdate - Function to handle the update of the quantity.
 * @param {boolean} props.isMobile - Flag to indicate if the view is mobile.
 * @param {object} props.giftItem - contain all value in an object
 * @param props.addOnName
 * @param props.addOnPrice
 * @returns {React.ReactElement} jsx for the clickable main product panel info component.
 */
const UpdateQuantity = ({
  quantity,
  itemIndex,
  productId,
  handleUpdate,
  isMobile,
  giftItem,
  addOnName,
  addOnPrice,
  ...props
}) => {
  const classes = useStyles(props);
  /**
   *
   */
  const handleAdd = () => {
    handleUpdate({ quantity: quantity + 1, itemIndex, productId, giftItem, addOnName, addOnPrice, isAddonAdded: true });
  };

  /**
   *
   */
  const handleRemove = () => {
    handleUpdate({
      quantity: quantity - 1,
      itemIndex,
      productId,
      giftItem,
      addOnName,
      addOnPrice,
      isAddonAdded: false,
    });
  };
  return (
    <span className="addon-qty">
      <div className={`${classes.addonsQtyBlock} ${!isMobile && classes.dAddonsQtyBlock} `}>
        {quantity === 1 ? (
          <RemoveIcon className={`${classes.qtyUpdateDisable} ${classes.qtyDecrease}`} />
        ) : (
          <RemoveIcon onClick={handleRemove} className={classes.qtyDecrease} />
        )}
        <span className={classes.addonQtyValue}>{quantity}</span>
        {quantity === 9 ? (
          <AddIcon className={`${classes.qtyUpdateDisable} ${classes.qtyIncrease}`} />
        ) : (
          <AddIcon onClick={handleAdd} className={`${classes.qtyIncrease}`} />
        )}
      </div>
    </span>
  );
};

const propTypes = {
  quantity: PropTypes.number.isRequired,
  itemIndex: PropTypes.number.isRequired,
  productId: PropTypes.string.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  giftItem: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  addOnName: PropTypes.string.isRequired,
  addOnPrice: PropTypes.number.isRequired,
};

UpdateQuantity.propTypes = propTypes;

export default UpdateQuantity;
